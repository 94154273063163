button {
  background: none;
  border: 0;
  color: inherit;
  font: inherit;
  line-height: normal;
  overflow: visible;
  padding: 0;
  user-select: none;
  cursor: pointer;

  &:focus {
    outline: none;
  }
}

.button-row {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-end;
  margin: 1.625em -0.375em;
  user-select: none;

  > * {
    margin: 0.375em;
  }

  &.center {
    justify-content: center;
  }

  .r-align,
  &.text-right > * {
    @include media('>=sm') {
      margin-left: auto;
    }
  }
}

.log-out-btn {
  @include btn(transparent, lightgray);
  width: 100%;
}

.ant-upload.ant-upload-drag p.ant-upload-drag-icon .anticon {
  color: $primary-color;
}
.ant-pagination-prev:hover .ant-pagination-item-link,
.ant-pagination-next:hover .ant-pagination-item-link {
  color: $secondary-color;
}
.ant-input:focus,
.ant-input-focused {
  border-color: $secondary-color;
  box-shadow: 0 0 0 2px $secondary-color;
}
.ant-input:hover {
  border-color: $secondary-color;
}
.ant-pagination-item:hover a {
  color: $secondary-color;
}

.b2b-primary {
  position: relative;
  display: inline-block;
  font-weight: 700;
  white-space: nowrap;
  text-align: center;
  // user-select: none;
  padding: 4px 15px;
  height: 38px;
  letter-spacing: 0.05em;
  font-size: 13px;
  transition: none;
  color: #fff;
  background: $primary-color;
  border: 2px solid $primary-color;
  border-radius: 90px;
  line-height: normal;
  cursor: pointer;
}

.b2b-primary:hover {
  opacity: 0.8;
}

.b2b-secondary {
  position: relative;
  display: inline-block;
  font-weight: 700;
  white-space: nowrap;
  text-align: center;
  // user-select: none;
  padding: 4px 15px;
  height: 38px;
  letter-spacing: 0.05em;
  font-size: 13px;
  transition: none;
  color: #fff;
  background: #fff;
  border: 2px solid $primary-color !important;
  border-radius: 90px;
  line-height: normal;
  cursor: pointer;
}

.b2b-secondary:hover {
  opacity: 0.8;
}
