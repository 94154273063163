// .ant-menu-item-selected{
//   background-color: rgb(37,157,171) !important;
// }

.ant-menu-dark .ant-menu-item > span > a {
  color: #fff;
}

.ant-menu-dark .ant-menu-item:hover {
  background: #81c3eb;
}

.ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected {
  //background-color: #445786;
  //background: linear-gradient(180deg, #3372ED 0%, #1138C2 100%);
  background: #3372ed;
}

.submenu {
  text-align: center;
  color: $wht;
  font-size: 18px;
}

.ant-menu-dark.ant-menu-inline .ant-menu-item,
.ant-menu-dark.ant-menu-inline .ant-menu-submenu-title {
  width: 100%;
}

.ant-menu.ant-menu-dark .ant-menu-submenu-title .ant-menu-submenu-arrow,
.ant-menu-dark .ant-menu-sub .ant-menu-submenu-title .ant-menu-submenu-arrow,
.ant-menu.ant-menu-dark .ant-menu-sub .ant-menu-submenu-title .ant-menu-submenu-arrow {
  opacity: 0;
}
